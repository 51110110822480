#outer_div {
  display: flex;
  border: 1px solid #bd9d9d;

  /* padding-left: 30px;
margin: 10px 30px;
padding-right: 30px; */
}

#registerForm {
  background-color: aqua;
  /* width: 100%;  */
  width: 50%;
  padding-left: 30px;
  /* justify-content: center; */
}

#img {
  width: fit-content;
  height: fit-content !important;
}

input {
  border: 1px solid black;
  width: 60%;
}
label {
  font-size: 22px;
  /* margin-top: 10px;
    padding-top: 10px; */
}

/* btns */
#submit {
  align-items: center;
  background-color: #f9f5f4;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-top: 20px;
  margin-left: 18%;
}

#submit:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}

#submit:hover:after {
  color: #fff;
}

#submit:active {
  color: #e3e2e2;
  outline: 0;
}

#submit:hover {
  outline: 0;
}

form {
  display: block;
  margin: auto;
  margin-top: 10%;
}

/* navbar */
#nav {
  display: flex;
  justify-content: space-around;
}
#nav p {
  padding: 10px;
  margin: 10px;
  font-weight: 700;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
